import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DsMenuItem = _resolveComponent("DsMenuItem");

  var _component_ElSubMenu = _resolveComponent("ElSubMenu");

  return _openBlock(), _createBlock(_component_ElSubMenu, {
    index: _ctx.title
  }, {
    title: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.title), 1)];
    }),
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, function (menuItem, index) {
        return _openBlock(), _createBlock(_component_DsMenuItem, {
          key: index,
          label: menuItem.label,
          to: menuItem.to,
          onClick: menuItem.onClick,
          style: _normalizeStyle({
            height: '40px',
            marginBottom: index + 1 === _ctx.items.length ? '30px' : null
          })
        }, null, 8, ["label", "to", "onClick", "style"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["index"]);
}