import "core-js/modules/es.function.name.js";
import { defineComponent, computed } from 'vue';
import DsAside from '@/components/DsAside';
import PdfViewer from '@/components/PdfViewer.tsx';
import { ElContainer, ElMain } from 'element-plus';
import router from '@/router';
export default defineComponent({
  components: {
    ElContainer: ElContainer,
    ElMain: ElMain,
    DsAside: DsAside,
    PdfViewer: PdfViewer
  },
  setup: function setup() {
    var loggued = computed(function () {
      return router.currentRoute.value.name !== 'Login';
    });
    return {
      loggued: loggued
    };
  }
});