import { InjectionKey } from 'vue'
import { useStore as Store__ } from 'vuex'
import { createStore, Store } from "vuex";


import preferences, { State as PreferencesState } from "./preferences/preferences"
import user, { State as UserState } from "./user/user"

interface State {
  preferences: PreferencesState
  user: UserState
}

export const key: InjectionKey<Store<State>> = Symbol()

export default createStore({
  modules: {
    preferences,
    user
  },
});


export function useStore(): Store<State> {
  return Store__(key)
}