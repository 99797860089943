import { RouterLink } from 'vue-router'

import { defineComponent, ref } from 'vue'
import { ElMenuItem } from 'element-plus'
import router from '@/router'

export default defineComponent({
  name: 'DsMenuItem',
  props: { label: String, to: { type: String, required: true }, style: Object },
  components: { ElMenuItem },
  setup(props) {
    const currentRoute = ref(router.currentRoute)
    return () => {
      return (
        <RouterLink to={props.to} style='fontWeight: 400;'>
          <ElMenuItem index={props.to || 'Menu'} style={{ ...props.style, backgroundColor: currentRoute.value.path === props.to ? ' rgb(190, 193, 196,0.4)' : '', }}>
            {props.label}
          </ElMenuItem>
        </RouterLink>
      )
    }
  },
})
