import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DsAside = _resolveComponent("DsAside");

  var _component_router_view = _resolveComponent("router-view");

  var _component_ElMain = _resolveComponent("ElMain");

  var _component_PdfViewer = _resolveComponent("PdfViewer");

  var _component_ElContainer = _resolveComponent("ElContainer");

  return _openBlock(), _createBlock(_component_ElContainer, null, {
    default: _withCtx(function () {
      return [_ctx.loggued ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_DsAside), _createVNode(_component_ElMain, {
        id: "main"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_router_view)];
        }),
        _: 1
      })], 64)) : (_openBlock(), _createBlock(_component_router_view, {
        key: 1
      })), _createVNode(_component_PdfViewer)];
    }),
    _: 1
  });
}