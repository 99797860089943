import { defineComponent, onMounted, ref } from 'vue'

import { ElButton, ElUpload } from 'element-plus'
import { FolderDelete } from '@element-plus/icons-vue'
import {
  FileHandler,
  ElUploadRequestOptions,
} from 'element-plus/es/components/upload/src/upload.type'

import { apiCall } from '@/utils/requests'
import { Model } from './DsForm'

export const download = (content: Blob, fileName: string) => {
  let blob = content
  if (!(blob instanceof Blob)) {
    blob = new Blob([content])
  }
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export default defineComponent({
  props: {
    document: { type: String, required: true },
    documentId: { type: String, required: true },
    field: { type: String, required: true },
    useFilename: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  setup(props) {
    const fileList = ref([])

    onMounted(async () => {
      fileList.value = await getFileList(
        props.document,
        props.documentId,
        props.field
      ) || []
    })

    const handleRemove: FileHandler = async (file, fileList) => {
      try {
        await apiCall('medias/document', 'DELETE', undefined, {
          document: props.document,
          document_id: props.documentId,
          field: props.field,
          filename: file.name,
          check_if_exists: false,
        })
      } catch (error) {
        console.log(error)
        throw new Error()
      }
    }

    return () => (
      <ElUpload
        action="#"
        fileList={fileList.value}
        listType="picture"
        httpRequest={(value: ElUploadRequestOptions) =>
          uploadFile(props.document, props.documentId, props.field, value.file)
        }
        onRemove={handleRemove}
      >
        {fileList.value.length === 0 && props.disabled && <span style="cursor:default;display: flex;align-items: center;height:1em">No Files <FolderDelete style="height:inherit;width:auto;margin-left:5px"></FolderDelete></span>}
        {!props.disabled && <ElButton>Add a file</ElButton>}
      </ElUpload>
    )
  },
})

const uploadFile = async (
  document: string,
  documentId: string,
  field: string,
  file: File
) => {
  try {
    const url = await apiCall('medias/upload-signin-url', 'PATCH', undefined, {
      document: document,
      document_id: documentId,
      field: field,
      filename: file.name,
      check_if_exists: false,
    })

    const requestInit: RequestInit = { method: 'PUT' }
    requestInit.body = file

    const resp = await fetch(new Request(url[0], requestInit))
    const data = await resp.text()
  } catch (error) {
    console.log(error)
  }
}

const getFileList = async (
  document: string,
  documentId: string,
  field: string
) => {
  try {
    return await apiCall('medias/download-signin-url', 'PATCH', undefined, {
      document: document,
      document_id: documentId,
      field: field,
    })
  } catch (error) {
    console.log(error)
  }
}

export const copyAllFiles = async (model: Model, value: any, fromId: string | undefined, toId: string, inputKey: string | null = null, collection: string): Promise<void> => {
  if (fromId === undefined) {
    throw new Error('fromId is undefined')
  }

  if (model.type === 'object') {
    for (const [key, input] of Object.entries(model.properties)) {
      await copyAllFiles(input, value[key], fromId, toId, `${inputKey || ''}.${key}`, collection)
    }
  } else if (model.type === "array") {
    value && value.map((v: any, index: number) => { copyAllFiles(model.items, v, fromId, toId, `${inputKey || ''}.${index}`, collection) })
  } else if (model.type === "file" && inputKey) {
    const files = await getFileList(collection, fromId, inputKey)
    for (const file of files) {
      const blob = await fetch(file.url).then(res => res.blob())
      console.log('Got ', file.name, ' Uploading with new id')
      await uploadFile(collection, toId, inputKey, new File([blob], file.name))
    }
  }
}