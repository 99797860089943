import { User } from "firebase/auth";
import { MutationTree } from 'vuex'


export interface State {
  user: null | User
}

const state: State = {
  user: null
}

const mutations: MutationTree<State> = {
  setUser(state, payload) {
    state.user = payload
  },
}

export default {
  state,
  mutations,
}