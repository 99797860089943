import { apiCall } from "@/utils/requests"
import { GetterTree, MutationTree, ActionTree } from 'vuex'


export interface Preference {
  id?: string
  created_at?: Date
  updated_at?: Date
  collection: string
  name: string
  type: string
  value: any
}

export interface State {
  preferences: null | Array<any>
}

const state: State = {
  preferences: null,
}

const getters: GetterTree<State, State> = {
  filtersPreferences: (state) => state.preferences && state.preferences.filter((pref: Preference) => pref.type === 'filter'),
  exportsPreferences: (state) => state.preferences && state.preferences.filter((pref: Preference) => pref.type === 'export')
}

const mutations: MutationTree<State> = {
  setPreferences(state: State, data: Array<Preference>) {
    state.preferences = data
  },
  addPreference(state: State, data: Preference) {
    state.preferences && state.preferences.push(data)
  },
  deletePreference(state: State, id: string) {
    if (state.preferences) {
      const preferenceIndex = state.preferences.findIndex((value: Preference,) => id === value.id)
      state.preferences.splice(preferenceIndex, 1)
    } else {
      console.log("Preference not found")
    }
  },
}

const actions: ActionTree<State, State> = {
  async getPreferences({ commit }, collection: string) {
    const preferences = await apiCall('preferences/' + collection, "GET")
    commit('setPreferences', preferences)
  },
  async createPreference({ commit }, preference: Preference) {
    const createdPreference = await apiCall('preferences', "POST", preference)
    const newsPreferences = state.preferences !== null ? [...state.preferences, createdPreference] : [createdPreference]
    commit('setPreferences', newsPreferences)
  },
  async deletePreference({ commit }, preferenceId: string) {
    await apiCall('preferences/' + preferenceId, "DELETE")
    commit('deletePreference', preferenceId)
  },
  getPreferenceById({ state }, id: string) {
    console.log('getPreferenceByIdhave been called')
    return (state.preferences && state.preferences.find(
      (pref: Preference) => pref.id === id)
    )
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}