import { defineComponent } from 'vue'

import { ElDrawer, ElButton } from 'element-plus'


export default defineComponent({
    name: 'DsIcon',
    components: { ElDrawer, ElButton },
    props: {
        text: { type: String }
    },
    setup(props, { slots }) {
        return () => (
            <span style="display:flex;align-items: center;">{slots.default && slots.default()} <span>&nbsp;{props.text}</span></span>
        )
    },
})
