import { getCurrentUser } from "@/services/auth";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ProductionOrders",
    component: () => import("@/views/ProductionOrder"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("@/views/Orders"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("@/views/Clients"),
  },
  {
    path: "/components",
    name: "Components",
    component: () => import("@/views/Components"),
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () => import("@/views/Suppliers"),
  },
  {
    path: "/recipes",
    name: "Recipes",
    component: () => import("@/views/Recipes"),
  },
  {
    path: "/my-todo",
    name: "My Todo",
    component: () => import("@/views/MyTodos"),
  },
  {
    path: "/supplies",
    name: "Supplies",
    component: () => import("@/views/Supplies"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const user = await getCurrentUser()
  if (to.name !== 'Login' && !user) return { name: 'Login' }
  else if (to.name === 'Login' && user) return { path: '/' }
})

// import Vue from 'vue';

// router.afterEach((to, from) => {
//   Vue.nextTick(() => {
//     document.title = `${'Cabeceo'} - ${to.meta.name}`
//   });
// })

export default router;
