import Login from '@/views/Login';
import { FirebaseError, initializeApp } from 'firebase/app'
import { signInWithEmailAndPassword, User, signOut as firebaseSignOut } from 'firebase/auth'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import firebaseConfig from '../credentials/firebaseConfig.json'

import store from "../store";
import { showMessage } from '@/utils/messages'
import router from '@/router';

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

export async function signIn(email: string, password: string): Promise<string | void> {
    try {
        const response = await signInWithEmailAndPassword(
            auth, email, password
        )
        router.push('/')
        showMessage('Sucessfully logged', 'success')
    } catch (error: any) {
        switch (error.code) {
            case 'auth/invalid-email':
                return 'Invalid email'
            case 'auth/wrong-password':
                return 'Wrong password'
            case 'auth/user-not-found':
                return 'No user for this email'

            default:
                return error.code
        }
    }
}


export async function signOut(): Promise<void> {
    try {
        await firebaseSignOut(auth)
        showMessage('Sucessfully logged out', 'success')
        router.push('/login')
    } catch (error: any) {
        showMessage(error.code, 'error')
    }
}

/**
 * Return promise of current user from State, resolved when 
 * the user is set in the state (when `auth` module is initialized)
 * 
 * Use case: need of user for routing choice, but auth is not yet 
 * initialized and auth.currentUser return null.
 */
export function getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
        const unsuscribe = onAuthStateChanged(auth, user => {
            store.commit('setUser', user)
            unsuscribe()
            resolve(user);
        }, reject)
    })
};