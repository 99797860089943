import { defineComponent, PropType, watch } from 'vue'
import { apiCall } from '@/utils/requests'
import { ElDialog, ElButton } from 'element-plus'
import { Download as DownloadIcon } from '@element-plus/icons'
import { download } from './DsUpload'

import { reactive } from 'vue'

export const pdfStore = reactive<{ show: boolean; value: any; open: any; close: any }>({
  show: false,
  value: { url: null, type: null },
  open(value: any) {
    console.log(value)
    this.value = value
    this.show = true
  },
  close() {
    this.show = false
    this.value = { url: null, type: null }
  },
})

export const getPdf = async (value: any) => {
  const file = await apiCall(
    'recipes/action',
    'POST',
    value,
    {
      action_name: 'generate_pdf',
    },
    { responseType: 'blob' }
  )
  pdfStore.open({ type: file.type, file: file, url: URL.createObjectURL(file) })
}


export default (() =>
  defineComponent({
    name: 'DsView',
    components: {
      ElDialog
    },
    setup() {
      return () => (
        <div class="object-viewer">
          <ElDialog
            width="1000px"
            top='10px'
            title='Preview of PDF'
            modelValue={pdfStore.show}
            onClose={() => pdfStore.close()}
            lockScroll={true}
            v-slots={{
              default: () => (
                <div style="height: 75vh">
                  <embed
                    ref="object-pdf-viewer"
                    width="100%"
                    style="height: 100%;"
                    src={pdfStore.value.url}
                    type={pdfStore.value.type}
                  />
                </div>
              ),
              footer: () => (
                <ElButton
                  type='primary'
                  size='medium'
                  icon={DownloadIcon}
                  onClick={() => download(pdfStore.value.file, 'bolla.pdf')}
                >
                  Download file
                </ElButton>
              )
            }}
          >
          </ElDialog>
        </div>
      )
    },
  }))()
