import { defineComponent, computed } from 'vue'
import { RouterLink } from 'vue-router'

import {
  ElContainer,
  ElAside,
  ElMain,
  ElMenu,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElFooter,
  ElPopover,
  ElIcon,
  ElMenuItem,
  ElAvatar,
} from 'element-plus'
import {
  ArrowDown,
  SwitchButton,
  User as UserIcon, Files as FilesIcon
} from '@element-plus/icons-vue'
import { useStore } from '@/store'

import DsMenuItem from '@/components/DsMenuItem'
import DsSubMenu from '@/components/DsSubMenu.vue'
import DsIcon from '@/components/DsIcon'

import { signOut } from '@/services/auth'

export default defineComponent({
  components: {
    ElContainer,
    ElAside,
    ElMain,
    ElMenu,
    DsSubMenu,
    DsMenuItem,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElPopover,
    ArrowDown,
    ElIcon,
    ElMenuItem,
    ElAvatar,
    SwitchButton,
    UserIcon,
    DsIcon,
    FilesIcon,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.user)

    const organizationName = 'Cabeceo'
    const handleCommand = (value: any) => {
      if (value === 'logout') {
        signOut()
      }
    }

    const items = [
      { label: 'Clients', to: '/clients' },
      { label: 'Components', to: '/components' },
      { label: 'Suppliers', to: '/suppliers' },
      { label: 'Supplies', to: '/supplies' },
    ]

    return () => (
      <ElAside width="210px">
        <ElMenu
          mode="vertical"
          background-color="#EEF1F5"
          {...{ id: 'nav' }}
          style="display: flex;flex-direction: column;"
        >
          <ElDropdown
            trigger="click"
            style="width:100%;margin-bottom:30px"
            onCommand={(value) => handleCommand(value)}
            v-slots={{
              default: () => (
                <ElMenuItem
                  class="el-dropdown-link"
                  style="justify-content:space-between;font-weight: 500;font-size:18px"
                >
                  <ElAvatar
                    style="background-color: #3e3e53"
                    size="small"
                    shape="circle"
                  >
                    {organizationName[0]}
                  </ElAvatar>
                  {organizationName}
                  <ElIcon style="font-size: 12px">
                    <ArrowDown />
                  </ElIcon>
                </ElMenuItem>
              ),
              dropdown: () => (
                <ElDropdownMenu style="width: 180px">
                  <ElDropdownItem disabled>
                    {user.value !== null && user.value.email}
                  </ElDropdownItem>
                  <RouterLink
                    to="/users"
                    style="font-weight: 400; text-decoration: none;"
                  >
                    <ElDropdownItem divided icon={UserIcon}>
                      Users
                    </ElDropdownItem>
                  </RouterLink>
                  <RouterLink
                    to="/recipes"
                    style="font-weight: 400; text-decoration: none;"
                  >
                    <ElDropdownItem icon={FilesIcon}>
                      Recipes
                    </ElDropdownItem>
                  </RouterLink>
                  <ElDropdownItem command="logout" divided icon={SwitchButton}>
                    <span>Sign out</span>
                  </ElDropdownItem>
                </ElDropdownMenu>
              ),
            }}
          ></ElDropdown>
          <div style="flex-grow:1">
            <DsMenuItem to="/" label="⚡️ Production Orders" style={{ fontWeight: 500, textDecoration: null }} />
            <DsMenuItem to="/orders" label="📬 Clients Orders" style={{ fontWeight: 500, textDecoration: null }} />
            <DsSubMenu title="🗂 Records" items={items} style="font-weight: 500; text-decoration: none;" />
            <DsMenuItem to="/my-todo" label="📝 My todo" style={{ fontWeight: 500, textDecoration: null }} />
          </div>
          <ElFooter style="margin:10px">
            <img src={require('@/assets/login.png')} alt="Cabeceo" style="width:100%" />
          </ElFooter>
        </ElMenu>
      </ElAside>
    )
  },
})
