import { getCurrentUser } from '@/services/auth'
import { showMessage } from '@/utils/messages'

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://cabeceo-backend-qr4xka625a-ew.a.run.app'
    : 'http://localhost:8000'
const PREFIX = '/api/'
export const apiCall = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  body?: any,
  params?: any,
  options?: any
) => {
  if (options === undefined) {
    options = { responseType: 'json' }
  } else if (options.responseType === undefined) {
    options.responseType = 'json'
  }

  const headers = new Headers()
  const requestInit: RequestInit = { method }
  const user = await getCurrentUser()

  const urlParams = new URLSearchParams(params)

  if (user) {
    headers.append('authorization', await user.getIdToken())
  }
  if (body !== undefined) {
    requestInit.body = JSON.stringify(body)
    headers.append('content-type', 'application/json')
  }
  requestInit.headers = headers
  const request = new Request(
    `${BASE_URL}${PREFIX}${url}?${urlParams.toString()}`,
    requestInit
  )

  const resp = await fetch(request)
  if (resp.ok) {
    let data
    if (options.responseType === 'json') {
      data = await resp.json()
    } else if (options.responseType === 'blob') {
      data = await resp.blob()
    }
    return data
  } else {
    if (resp.status === 400) {
      showMessage((await resp.json()).detail, 'warning')
    }
    throw new Error()
  }
}
