import { defineComponent } from 'vue';
import { ElSubMenu } from 'element-plus';
import DsMenuItem from '@/components/DsMenuItem';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  components: {
    ElSubMenu: ElSubMenu,
    DsMenuItem: DsMenuItem
  }
});